<template>
    <div class="m-2" :style="`direction:`+lang.dir+`;`">
        <bread-crumbs :items="items"></bread-crumbs>
        <v-row class="mainrow m-2" style="direction:rtl;width:80% !important;display:none">
            <v-col cols="12">
                <b-button class="mytab1 btn btn-sm btn-success m-2" style="width:120px;" @click="printPDF()">طباعة الكشف</b-button> 
                <b-button class="mytab1 btn btn-sm btn-success m-2" style="width:120px;" @click="printQuot()">استعراض PDF</b-button> 
            </v-col>
        </v-row>
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;text-align:center;`">
            <v-col cols="12" :style="`direction:`+lang.dir+`;`">
                <b-table-simple class="m-2">
                    <tbody>
                        <tr>
                            <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;">{{lang.customer_name}}</th>
                            <td style="border:1px solid #555;text-align:center;">{{ customer.full_name }}</td>
                            <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;">{{lang.mobile}}</th>
                            <td style="border:1px solid #555;text-align:center;">{{ customer.mobile1 }}</td>
                        </tr>
                        <tr>
                            <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;">{{lang.company_name}}</th>
                            <td style="border:1px solid #555;text-align:center;">{{ customer.company_name }}</td>
                            <th style="border:1px solid #555;text-align:center;background:#000;color:#FFF;">{{lang.company_vatid}}</th>
                            <td style="border:1px solid #555;text-align:center;">{{ customer.company_vatid }}</td>
                        </tr>
                    </tbody>
                </b-table-simple>
            </v-col>
        </v-row>
        <v-row class="mainrow m-2" :style="`direction:`+lang.dir+`;text-align:center;`">
             <v-col cols="12">
                <b-table-simple class="m-2">
                    <thead>
                        <tr>
                            <th style="width:100px;">{{lang.date}}</th>
                            <th>{{lang.description}}</th>
                            <th style="width:100px;">{{lang.credit}}</th>
                            <th style="width:100px;">{{lang.depit}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item,index) in staterows" :key="index">
                            <td>{{item.date}}</td>
                            <td>{{item.name}}</td>
                            <td>{{item.credit}}</td>
                            <td>{{item.depit}}</td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>{{credit_tot}}</th>
                            <th>{{depit_tot}}</th>
                        </tr>
                    </tfoot>
                </b-table-simple>
             </v-col>
        </v-row>
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import axios from 'axios'
export default{
    components: { breadCrumbs },
    data() {
        return {
            
            customer:{

            },
            staterows:[]
        }
    },
    computed:{
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        items: function(){
            
            return {
                text: this.lang.client_statement,
                disabled: true,
                href: '/client-statement',
            }
            
        },
        credit_tot: function() {
            let tot = 0;
            if(this.staterows.length > 0){
                for(let i = 0;i<this.staterows.length;i++){
                    if(String(parseFloat(this.staterows[i].credit)) != 'NaN')
                        tot = parseFloat(tot) + parseFloat(this.staterows[i].credit)
                }
            }
            return tot;
        },
        depit_tot: function() {
            let tot = 0;
            if(this.staterows.length > 0){
                for(let i = 0;i<this.staterows.length;i++){
                    if(String(parseFloat(this.staterows[i].depit)) != 'NaN')
                        tot = parseFloat(tot) + parseFloat(this.staterows[i].depit)
                }
            }
            return tot;
        },
    },
    methods: {
        getStatement(){
            const post = new FormData();
            post.append("type","getStatment");
            post.append('defBranch',localStorage.getItem('defBranch'));
            post.append('financeYear',localStorage.getItem('financeYear'));
            post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append("lang",this.lang.langname)
            post.append("data[clientid]",this.$route.params.id)
            axios.post(
                this.$store.state.SAMCOTEC.r_path , post
            ).then((response) => {
                // // console.log(response.data);
                this.customer = response.data.results.data.cus;
                if(response.data.results.data.inv.length > 0){
                    for(let i = 0;i< response.data.results.data.inv.length;i++){
                        this.staterows.push(response.data.results.data.inv[i]);
                    }
                }
                if(response.data.results.data.rece.length > 0){
                    for(let j = 0;j< response.data.results.data.rece.length;j++){
                        this.staterows.push(response.data.results.data.rece[j]);
                    }
                }

            })
        }
    },
    created() {
        this.getStatement();
    },
}
</script>